import { defineStore } from 'pinia'

export const useBookingStore = defineStore({
    id: 'booking',
    state: () => {
        return {
            canSubmitBooking: false,
            disableDates: [],
            bookingPayload: {
                is_patient_portal: true,
                patient_id: null,
                branch_id: null,
                package_id: 0,
                package_fee: 0,
                other_charges_line: null,
                other_charges_fee: null || 0,
                booking_date: null,
                booking_start_time: null,
                booking_end_time: null,
                is_dependent: false,
                dependent: {
                    relationship: '',
                    is_minor: false,
                },
                // For Validation
                package_name: null,
                branch_name: null,
                branch_address: null,
                riders: null,
            },
            bookingSummary: {
                selectedBranch: null,
                selectedPatient: null,
                selectedPackage: null,
                selectedAddOns: null,
                selectedDate: null,
                selectedTime: null,
                packageFee: null,
                miscFee: null,
                discount: null || 0,
                totalFee: null || 0,
            },
        }
    },
    getters: {},
    actions: {},
})