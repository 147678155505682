import patient from "@/classes/UserData";
import http from '@/axios'
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject()
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()
interface DataItem {
    description: string;
    status: string;
    index: number;
}

const RELATIONSHIPS = [
    { id: 'father', label: 'Father / 父', value: 'Father'},
    { id: 'mother', label: 'Mother / 母', value: 'Mother'},
    { id: 'brother', label: 'Brother / 兄弟', value: 'Brother'},
    { id: 'sister', label: 'Sister / 姉妹', value: 'Sister'},
    { id: 'grandfather', label: 'Grandfather / 祖母', value: 'Grandfather'},
    { id: 'grandmother', label: 'Grandmother / 祖父', value: 'Grandmother'},
    { id: 'spouse', label: 'Spouse / 配偶者', value: 'Spouse'}
];

const ILLNESS_STATUS = [
    { id: 'taking_medicine', label: 'Under Treatment / 治療中 (Taking medicine / 服薬)', value: 'Taking medicine'},
    { id: 'consultation_examination', label: ' Under Treatment / 治療中 (Consultation/Examination only 診察 / 検査のみ)', value: 'Consultation/examination'},
    // { id: 'under_observation', label: 'Under Observation / 経過観察中', value: 'Under Observation'},
    // { id: 'undergone_surgery', label: 'Undergone Surgery / 手術済', value: 'Undergone Surgery'},
    { id: 'recovered', label: 'Recovered / 完治', value: 'Recovered'},
]

class MedicalService {

    fetchIllnessConditions = () : Promise<object> => {
        return patient.getIllnessConditions()
    }

    getDefaultOptions( id: string) : { id: string, label: string, value: string }[] {
        return [
            { id: `${id}-yes`, label: 'Yes / はい', value: 'Yes'},
            { id: `${id}-no`, label: 'No / いいえ', value: 'No'},
        ]
    }

    // TODO Fetch HMO from API
    // getHmoOptions() : { id: string, label: string, value: string }[] {
    //     return [
    //         { id: `Intellicare`, label: 'Intellicare', value: 'Intellicare'},
    //         { id: `Maxicare`, label: 'Maxicare', value: 'Maxicare'},
    //     ]
    // }

    getIllnessStatus() : { id: string, label: string, value: string }[] {
        return ILLNESS_STATUS;
    }

    getRelationships() : { id: string, label: string, value: string }[] {
        return RELATIONSHIPS;
    }

    getSymptoms(): { label: string, value: string }[] {
         return  [
            { label: "Palpitations, Shortness of breath / 動悸、息切れ", value : "Palpitations, Shortness of breath"},
            { label: "Dry mouth, Polydipsia (excessive thirst) / 口渇多飲", value : "Dry mouth, Polydipsia (excessive thirst)"},
            { label: "Chest pain / 胸痛", value : "Chest pain"},
            { label: "Weight loss / 体重減少", value : "Weight loss"},
            { label: "Headache, Stiff shoulders / 頭痛、肩こり", value : "Headache, Stiff shoulders"},
            { label: "Swelling / むくみ", value : "Swelling"},
            { label: "Dizziness, Tinnitus (ringing or other noises in one or both of your ears) / めまい、耳鳴り", value : "Dizziness, Tinnitus (ringing or other noises in one or both of your ears)"},
            { label: "Sputum/Phlegm, Cough / 喀痰、咳", value : "Sputum/Phlegm, Cough"},
            { label: "Numbness in hands and feet, tongue twisters (involuntary tongue movement) / 手足のしびれ、舌のもつれ", value : "Numbness in hands and feet, tongue twisters (involuntary tongue movement)"},
            { label: "Constipation / 便秘", value : "Constipation"},
            { label: "General malaise / 全身倦怠感", value : "General malaise"},
            { label: "Diarrhea / 下痢", value : "Diarrhea"},
            { label: "None / 特になし", value : "None"},
        ]
    //    return  [
    //         { label: 'Frequent headaches/migraine (頻繁な頭痛／片頭痛)', value: 'Frequent headaches/migraine' },
    //         { label: 'Loss of consciousness (意識の喪失)', value: 'Loss of consciousness' },
    //         { label: 'Dizziness/balance problem (めまい／バランスの問題)', value: 'Dizziness/balance problem' },
    //         { label: 'Weakness/paralysis/numbness/tremors (脱力感・麻痺・しびれ・震え)', value: 'Weakness/paralysis/numbness/tremors' },
    //         { label: 'Blurring of vision/eye problem (かすみ目／目の問題)', value: 'Blurring of vision/eye problem' },
    //         { label: 'Hearing defect/ear problem (聴覚障害／耳の問題)', value: 'Hearing defect/ear problem' },
    //         { label: 'Frequent sore throat/colds/sneezing (のどの痛み・風邪・くしゃみが頻繁に出る)', value: 'Frequent sore throat/colds/sneezing' },
    //         { label: 'Persistent cough/shortness of breath (しつこい咳／息切れ)', value: 'Persistent cough/shortness of breath' },
    //         { label: 'Urination problem (排尿の問題)', value: 'Urination problem' },
    //         { label: 'Constipation/diarrhea (便秘／下痢)', value: 'Constipation/diarrhea' },
    //         { label: 'Abdominal pains (腹痛)', value: 'Abdominal pains' },
    //         { label: 'Genital organ problem/discharges (生殖器の問題／分泌物)', value: 'Genital organ problem/discharges' },
    //         { label: 'Easily tires on ordinary activity/walking (普段の活動・歩行で疲れやすい)', value: 'Easily tires on ordinary activity/walking' },
    //         { label: 'Chest pain/heaviness/palpitations (胸の痛み／重さ／動悸)', value: 'Chest pain/heaviness/palpitations' },
    //         { label: 'Yellowing of the eyes and skin (目や皮膚が黄色っぽい)', value: 'Yellowing of the eyes and skin' },
    //         { label: 'Hemorrhoids/bloody or black stool (痔／血便または黒色便)', value: 'Hemorrhoids/bloody or black stool' },
    //         { label: 'Exposure to tuberculosis (結核への曝露)', value: 'Exposure to tuberculosis' },
    //         { label: 'Bleeding tendency (出血しやすい)', value: 'Bleeding tendency' },
    //         { label: 'Skin problem/cysts/lumps (皮膚の問題/嚢胞/しこり)', value: 'Skin problem/cysts/lumps' },
    //         { label: 'Feet swelling/joint swelling (足のむくみ・関節のむくみ)', value: 'Feet swelling/joint swelling' },
    //         { label: 'Loss of appetite (食欲減少)', value: 'Loss of appetite' },
    //         { label: 'Weight loss/weight gain (体重減少／体重増加)', value: 'Weight loss/weight gain' },
    //         { label: 'Sleeping problem (睡眠障害)', value: 'Sleeping problem' },
    //         { label: 'Allergy (アレルギー)', value: 'Allergy' },
    //     ]
    }

    addDataToArray(data: DataItem[], description: string, status: string, index: number) :void {
        const existingData = data.find((e: any) => e.description === description);
            if (existingData) {
                existingData.status = status;
            } else {
                data.push({ description, status, index });
        }
    }

    getHmos = () : Promise<object> => {
        return http.get(`hmo/fetch?${TOKEN_PARAM_STRING}`);
    }

    getPatientMedicalQuestionnaire = (bookingId: number) : Promise<object> => {
        return http.get(`medical-questionnaire/fetch?booking_id=${bookingId}&${TOKEN_PARAM_STRING}`);
    }
    getPatientLatestMedicalQuestionnaire = () : Promise<object> => {
        return http.get(`medical-questionnaire/fetch-latest-medical-questionnaire?${TOKEN_PARAM_STRING}`);
    }

    submitMedicalQuestionnaire(payload: object) : Promise<object> {
        return http.post('medical-questionnaire/submit', Object.assign(payload, TOKEN_PARAM_OBJECT))
    }

    updateMedicalQuestionnaire(payload: object) : Promise<object> {
        return http.post('medical-questionnaire/update', Object.assign(payload, TOKEN_PARAM_OBJECT))
    }
}

export default new MedicalService()