import http from '@/axios'
import { IPages } from '@/interfaces/BaseInterface';

class Pages implements IPages {
    
    fetchSlug(slug: string) {
        return http.get(`cms/fetch/${slug}`);
    }
}

export default new Pages();
