import http from '@/axios'
import { IBooking } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject()
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class Booking implements IBooking {
    
    getBranches() {
        return http.get(`bookings/fetch-branches`);
    }
    
    getPackages() {
        return http.get(`bookings/fetch-packages?${TOKEN_PARAM_STRING}`);
    }

    getServices() {
        return http.get(`bookings/fetch-services?${TOKEN_PARAM_STRING}`);
    }
    
    getAllergyTests() {
        return http.get(`bookings/fetch-allergy-tests?${TOKEN_PARAM_STRING}`);
    }
    
    getGetAvailableDates(payload: object) {
        return http.post(`individual-bookings/fetch-available-dates`, payload);
    }

    getTimeSlots(payload: object) {
        return http.post(`individual-bookings/fetch-date-and-time`, payload);
    }
    
    submitBooking(payload: object) {
        return http.post(`individual-bookings/store-individual-booking`, Object.assign(payload, TOKEN_PARAM_OBJECT));
    }

    updateSoftBooking(data: object) {
        return http.post(`bookings/update-soft-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }
    applyVoucher(payload: object){
        return http.post(`vouchers/apply`, Object.assign(payload, TOKEN_PARAM_OBJECT))
    }
}

export default new Booking();
